<template>
  <div
    v-if="!authState.isAuthenticated"
    class="alert alert-warning fade show"
    role="alert"
  >
    <strong>Hi!</strong> you need to
    <router-link
      to="/sign-in"
      class="btn btn-outline-success btn-large"
      active-class="active"
      role="button"
      >Login</router-link
    >
    to use the site!
  </div>
  <nav class="container navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarToggler"
        aria-controls="navbarToggler"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarToggler">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li
            class="nav-item dropdown"
            v-if="
              hasRole([
                AuthRole.PurchaseOrderReader,
                AuthRole.Shipper,
                AuthRole.ShipperAdmin,
              ])
            "
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              POs
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link
                  to="/purchaseOrder"
                  class="dropdown-item"
                  active-class="active"
                  >View</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  :class="
                    !hasRole([
                      AuthRole.Shipper,
                      AuthRole.ShipperAdmin,
                      AuthRole.Purchaser,
                      AuthRole.GlassesOrderReader,
                      AuthRole.InnovationsPlaceOrder,
                    ]) && 'disabled'
                  "
                  active-class="active"
                  :to="{ name: 'purchaseOrderNew' }"
                >
                  <i class="ion-compose"></i>New PO
                </router-link>
              </li>
              <li>
                <router-link
                  to="/shipment"
                  class="dropdown-item"
                  active-class="active"
                  >Shipments</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  :class="
                    !hasRole([AuthRole.Shipper, AuthRole.ShipperAdmin]) &&
                    'disabled'
                  "
                  active-class="active"
                  :to="{ name: 'trustedSupplier' }"
                >
                  <i class="ion-compose"></i>Trusted Supplier
                </router-link>
                <router-link
                  :to="{ name: 'purchase-order-receive' }"
                  class="dropdown-item"
                  :class="!hasRole([AuthRole.ShipperAdmin]) && 'disabled'"
                  active-class="active"
                  >Receive Inbound</router-link
                >
                <router-link
                  :to="{ name: 'containers' }"
                  class="dropdown-item"
                  :class="!hasRole([AuthRole.ShipperAdmin]) && 'disabled'"
                  active-class="active"
                  >Inbound</router-link
                >
              </li>
              <li>
                <router-link
                  to="/supplierMaintenanceScreen"
                  class="dropdown-item"
                  :class="!hasRole([AuthRole.Purchaser]) && 'disabled'"
                  active-class="active"
                  >Supplier Maintenance</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item" v-else>
            <a
              class="nav-link disabled"
              href="#"
              tabindex="-1"
              aria-disabled="true"
              >Purchase Orders</a
            >
          </li>
          <li
            class="nav-item dropdown"
            v-if="hasRole([AuthRole.WarehouseUser])"
          >
            <a
              id="warehouse-menu"
              class="nav-link dropdown-toggle"
              href="#"
              data-bs-toggle="dropdown"
              data-bs-display="static"
              aria-expanded="false"
            >
              Warehouse
            </a>
            <ul class="dropdown-menu" aria-labelledby="warehouse-menu">
              <li>
                <router-link
                  to="/print/frames"
                  class="dropdown-item"
                  active-class="active"
                  >Print Frame Labels</router-link
                >
              </li>
              <li>
                <router-link
                  to="/print/packslip"
                  class="dropdown-item"
                  active-class="active"
                  >Print Packslip</router-link
                >
              </li>
              <li>
                <router-link
                  to="/framesPickingStatus"
                  class="dropdown-item"
                  active-class="active"
                  >Frame Picking Status</router-link
                >
              </li>
              <li>
                <router-link
                  to="/endlessAisleToBin"
                  class="dropdown-item"
                  active-class="active"
                  >Endless Aisle to bin</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'ea-glasses-orders' }"
                  class="dropdown-item"
                  active-class="active"
                  :class="!hasRole([AuthRole.WarehouseUser]) && 'disabled'"
                  >Endless Aisle Viewer</router-link
                >
              </li>

              <li
                class="dropdown-submenu"
                v-if="
                  hasRole([
                    AuthRole.GlassesOrderReader,
                    AuthRole.InnovationsPlaceOrder,
                  ])
                "
              >
                <a
                  class="dropdown-item dropdown-toggle"
                  href="#"
                  id="warehouse-innovations-drop"
                  data-bs-toggle="dropdown"
                >
                  Innovations
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <router-link
                      :to="{ name: 'glasses-orders' }"
                      class="dropdown-item"
                      :class="
                        !hasRole([AuthRole.GlassesOrderReader]) && 'disabled'
                      "
                      active-class="active"
                      >Orders</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="{ name: 'glasses-transmits' }"
                      class="dropdown-item"
                      :class="
                        !hasRole([AuthRole.GlassesOrderReader]) && 'disabled'
                      "
                      active-class="active"
                      >Transmits</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      :class="
                        !hasRole([AuthRole.InnovationsPlaceOrder]) && 'disabled'
                      "
                      active-class="active"
                      :to="{ name: 'innovations-place-order' }"
                    >
                      <i class="ion-compose"></i>Place Order
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      :class="
                        !hasRole([AuthRole.GlassesOrderWriter]) && 'disabled'
                      "
                      active-class="active"
                      :to="{ name: 'glasses-orders-ship' }"
                    >
                      <i class="ion-compose"></i>FBK Shipping
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      :class="
                        !hasRole([
                          AuthRole.InnovationsPlaceOrder,
                          AuthRole.GlassesOrderReader,
                        ]) && 'disabled'
                      "
                      active-class="active"
                      :to="{ name: 'glasses-orders-fbk' }"
                    >
                      <i class="ion-compose"></i>Hyalos Orders
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item" v-else>
                <a
                  class="nav-link disabled"
                  href="#"
                  tabindex="-1"
                  aria-disabled="true"
                  >Innovations</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item" v-else>
            <a
              class="nav-link disabled"
              href="#"
              tabindex="-1"
              aria-disabled="true"
              >Warehouse</a
            >
          </li>

          <li
            class="nav-item dropdown"
            v-if="
              hasRole([AuthRole.CycleCountReader, AuthRole.CycleCountAdmin])
            "
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Cycle Counts
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link
                  to="/cycleCount"
                  class="dropdown-item"
                  active-class="active"
                  >View</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  :class="
                    !hasRole([
                      AuthRole.CycleCountWriter,
                      AuthRole.CycleCountAdmin,
                    ]) && 'disabled'
                  "
                  active-class="active"
                  :to="{ name: 'cycle-count-editor' }"
                >
                  <i class="ion-compose"></i>New Cycle Count
                </router-link>
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  :class="!hasRole([AuthRole.CycleCountAdmin]) && 'disabled'"
                  active-class="active"
                  :to="{ name: 'cycle-count-bulk-upload' }"
                >
                  <i class="ion-compose"></i>Bulk Upload
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item" v-else>
            <a
              class="nav-link disabled"
              href="#"
              tabindex="-1"
              aria-disabled="true"
              >Cycle Counts</a
            >
          </li>

          <li
            class="nav-item dropdown"
            v-if="
              hasRole([
                AuthRole.Invoice,
                AuthRole.ShipperPriceAdmin,
                AuthRole.FinanceController,
              ])
            "
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Finance
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link
                  to="/invoice"
                  class="dropdown-item"
                  :class="!hasRole([AuthRole.Invoice]) && 'disabled'"
                  active-class="active"
                  >View Invoices</router-link
                >
              </li>
              <li>
                <router-link
                  to="/finance-reports"
                  class="dropdown-item"
                  :class="!hasRole([AuthRole.ShipperPriceAdmin]) && 'disabled'"
                  active-class="active"
                  >Finance Reports</router-link
                >
              </li>
              <li>
                <router-link
                  to="/finance-monthly-pulls"
                  class="dropdown-item"
                  :class="!hasRole([AuthRole.FinanceController]) && 'disabled'"
                  active-class="active"
                  >Monthly Pulls</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item" v-else>
            <a
              class="nav-link disabled"
              href="#"
              tabindex="-1"
              aria-disabled="true"
              >Invoices</a
            >
          </li>

          <li
            class="nav-item dropdown"
            v-if="hasRole([AuthRole.CampaignManager])"
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Marketing
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link
                  to="/campaigns"
                  class="dropdown-item"
                  :class="!hasRole([AuthRole.CampaignManager]) && 'disabled'"
                  active-class="active"
                  >Manage Campaigns</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item" v-else>
            <a
              class="nav-link disabled"
              href="#"
              tabindex="-1"
              aria-disabled="true"
              >Marketing</a
            >
          </li>

          <li
            class="nav-item dropdown"
            v-if="
              hasRole([
                AuthRole.StoreTransferAdmin,
                AuthRole.CustomerReturnsAdmin,
                AuthRole.DamageAdmin,
                AuthRole.QualityAssurance,
                AuthRole.JitReturns,
                AuthRole.InventoryAdmin,
                AuthRole.BulkReduction,
              ])
            "
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Inventory
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link
                  :to="{ name: 'store-inventory' }"
                  class="dropdown-item"
                  :class="!hasRole([AuthRole.StoreTransferAdmin]) && 'disabled'"
                  active-class="active"
                  >Store Inventory</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'report-damage' }"
                  class="dropdown-item"
                  :class="!hasRole([AuthRole.DamageAdmin]) && 'disabled'"
                  active-class="active"
                  >Damage</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'frame-breakage' }"
                  class="dropdown-item"
                  :class="!hasRole([AuthRole.QualityAssurance]) && 'disabled'"
                  active-class="active"
                  >Frame Breakage</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'inventory-adjust' }"
                  class="dropdown-item"
                  :class="!hasRole([AuthRole.InventoryAdmin]) && 'disabled'"
                  active-class="active"
                  >Adjustment</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'inventory-jit-returns' }"
                  class="dropdown-item"
                  :class="!hasRole([AuthRole.JitReturns]) && 'disabled'"
                  active-class="active"
                  >JIT returns</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'inventory-bulk-reductions' }"
                  class="dropdown-item"
                  :class="!hasRole([AuthRole.BulkReduction]) && 'disabled'"
                  active-class="active"
                  >Bulk Reductions</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'inventory-bulk-additions' }"
                  class="dropdown-item"
                  :class="
                    !hasRole([AuthRole.CustomerReturnsAdmin]) && 'disabled'
                  "
                  active-class="active"
                  >Bulk Customer Returns</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item" v-else>
            <a
              class="nav-link disabled"
              href="#"
              tabindex="-1"
              aria-disabled="true"
              >Inventory</a
            >
          </li>

          <li
            class="nav-item dropdown"
            v-if="
              hasRole([
                AuthRole.CustomerReturnsAdmin,
                AuthRole.CsAgent,
                AuthRole.CsAgentAdmin,
              ])
            "
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              >Returns
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link
                  :to="{ name: 'customer-returns' }"
                  class="dropdown-item"
                  :class="
                    !hasRole([AuthRole.CustomerReturnsAdmin]) && 'disabled'
                  "
                  active-class="active"
                  >Customer Returns</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'cs-returns-interface' }"
                  class="dropdown-item"
                  :class="
                    !hasRole([AuthRole.CsAgent, AuthRole.CsAgentAdmin]) &&
                    'disabled'
                  "
                  active-class="active"
                  >Returns Interface (CS)
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'returns-verification' }"
                  class="dropdown-item"
                  :class="
                    !hasRole([AuthRole.CustomerReturnsAdmin]) && 'disabled'
                  "
                  active-class="active"
                  >Returns Verification (WH)
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'returns-assigner' }"
                  class="dropdown-item"
                  :class="!hasRole([AuthRole.CsAgentAdmin]) && 'disabled'"
                  active-class="active"
                  >Returns Assigner (CS)
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'actionable-items' }"
                  class="dropdown-item"
                  :class="
                    !hasRole([AuthRole.CsAgentAdmin, AuthRole.CsAgent]) &&
                    'disabled'
                  "
                  active-class="active"
                  >Actionable Items (CS)
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'assigned-items' }"
                  class="dropdown-item"
                  :class="!hasRole([AuthRole.CsAgentAdmin]) && 'disabled'"
                  active-class="active"
                  >Assigned Items (CS)
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'return-items-viewer' }"
                  class="dropdown-item"
                  :class="
                    !hasRole([
                      AuthRole.CsAgent,
                      AuthRole.CustomerReturnsAdmin,
                      AuthRole.CsAgentAdmin,
                    ]) && 'disabled'
                  "
                  active-class="active"
                  >Return Items Viewer
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'return-items-acknowladgement-dashboard' }"
                  class="dropdown-item"
                  :class="
                    !hasRole([AuthRole.ReturnsAcknowledger]) && 'disabled'
                  "
                  active-class="active"
                  >Return Items Acknowledgement Dashboard (WH)
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item" v-else>
            <a
              class="nav-link disabled"
              href="#"
              tabindex="-1"
              aria-disabled="true"
              >Customer Returns</a
            >
          </li>
          <li class="nav-item dropdown" v-if="hasRole([AuthRole.JITter])">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Orders
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link
                  to="/jit/alcon"
                  class="dropdown-item"
                  active-class="active"
                  >Alcon</router-link
                >
              </li>
              <li>
                <router-link
                  to="/jit/bl"
                  class="dropdown-item"
                  active-class="active"
                  >Bausch & Lomb</router-link
                >
              </li>
              <li>
                <router-link
                  to="/jit/cooper"
                  class="dropdown-item"
                  active-class="active"
                  >Cooper</router-link
                >
              </li>
              <li>
                <router-link
                  to="/jit/jnj"
                  class="dropdown-item"
                  active-class="active"
                  >Johnson & Johnson</router-link
                >
              </li>
              <li>
                <router-link
                  to="/order/abb/manual"
                  class="dropdown-item"
                  active-class="active"
                  >ABB Manual</router-link
                >
              </li>
              <li>
                <router-link
                  to="/order/allied/sto"
                  class="dropdown-item"
                  active-class="active"
                  >Allied STO</router-link
                >
              </li>
              <li>
                <router-link
                  to="/jit/endlessAisles/nywd"
                  class="dropdown-item"
                  active-class="active"
                  >NYWD</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'ea-glasses-orders' }"
                  class="dropdown-item"
                  active-class="active"
                  >Endless Aisle Viewer</router-link
                >
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <router-link
                  to="/jit/settings"
                  class="dropdown-item"
                  active-class="active"
                  >JIT Settings</router-link
                >
              </li>
              <li>
                <router-link
                  to="/orders"
                  class="dropdown-item"
                  active-class="active"
                  >Orders</router-link
                >
              </li>
            </ul>
          </li>

          <li
            class="nav-item dropdown"
            v-if="
              hasRole([
                AuthRole.ShipperAdmin,
                AuthRole.Shipper,
                AuthRole.JITter,
                AuthRole.FinanceTier1,
                AuthRole.FinanceTier2,
                AuthRole.UserAdmin,
                AuthRole.WarehouseUser,
                AuthRole.LogisticsAdmin,
                AuthRole.JobRunnerOrderProcessing,
                AuthRole.JobRunnerWarehouse,
                AuthRole.JobRunnerGlassesTransmit,
                AuthRole.JobRunnerGlassesTransmit,
              ])
            "
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-gear" />
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li class="nav-item">
                <router-link
                  to="/settings/zebra"
                  class="dropdown-item"
                  active-class="active"
                  >Zebra</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  to="/jobs"
                  :class="{
                    'dropdown-item': true,
                    disabled: !hasRole([
                      AuthRole.JobRunnerOrderProcessing,
                      AuthRole.JobRunnerWarehouse,
                      AuthRole.JobRunnerGlassesTransmit,
                      AuthRole.JobRunnerGlassesTransmit,
                    ]),
                  }"
                  active-class="active"
                  >Jobs</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  to="/logistics/supplierQuotas"
                  active-class="active"
                  :class="{
                    'dropdown-item': true,
                    disabled: !hasRole([AuthRole.LogisticsAdmin]),
                  }"
                  >Supplier Quotas</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  to="/converters"
                  :class="{
                    'dropdown-item': true,
                    disabled: !hasRole([
                      AuthRole.JITter,
                      AuthRole.FinanceTier1,
                      AuthRole.FinanceTier2,
                    ]),
                  }"
                  active-class="active"
                  >Converters</router-link
                >
              </li>
              <li class="nav-item" v-if="hasRole([AuthRole.UserAdmin])">
                <router-link
                  to="/users"
                  class="dropdown-item"
                  active-class="active"
                  >Users</router-link
                >
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              :class="!authState.isAuthenticated && 'disabled'"
              href="#"
              tabindex="-1"
              :aria-disabled="!authState.isAuthenticated"
              @click="logout()"
              >logout</a
            >
          </li>
        </ul>
      </div>
      <div v-if="authState.isAuthenticated">
        <span class="navbar-text p-3">{{ authState.user.name }}</span>
        <img :src="authState.user.photo" alt="" class="user-photo" />
      </div>
      <span class="navbar-text p-3">v{{ currentVersion }}</span>
    </div>
  </nav>
</template>
<script setup lang="ts">
import { ref, Ref, onMounted } from 'vue';
import authStore from '../store/auth';
import { destroyTokens } from '../store/authToken';
import { AuthRole } from '@shared/authRoles';
import { getHealth } from '@/services/api.health';

const { state: authState, clearUser, hasRole } = authStore();
// console.log('state', authState.value.user)
let currentVersion: Ref<string> = ref('loading...');
getHealth()
  .then((response) => {
    // console.log('response', response);
    currentVersion.value = response.data.version;
  })
  .catch((e) => {
    console.error(e);
    currentVersion.value = 'error';
  });

const logout = () => {
  console.log('visit logout');
  destroyTokens();
  clearUser();
  // not available this.$router.push("/home");
};
</script>
<style>
.user-photo {
  border-radius: 100%;
  width: 50px;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 10%;
  left: 100%;
  margin-top: -1px;
}

.navbar-nav li:hover > ul.dropdown-menu {
  display: block;
}
</style>
